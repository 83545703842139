import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import MovingDots from '../components/MovingDots/MovingDots'
import '../styles/index-page.css'

export const IndexPageTemplate = ({ heading, subheading }) => (
  <section className="py-12 px-12 bg-appliscale">
    <div className="w-full mx-auto flex flex-col md:flex-row items-center">
      <MovingDots className="w-1/2"></MovingDots>
      <div>
        <h2 className="text-5xl leading-tight font-semibold font-heading text-blue-900">
          {heading}
        </h2>
        <p className="mt-6 mb-8 text-highlight leading-relaxed">{subheading}</p>
        <a className="hero-nav-item  text-highlight" href="/contact">
          Contact us
        </a>
      </div>
    </div>
  </section>
)

IndexPageTemplate.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        heading
        subheading
      }
    }
  }
`
