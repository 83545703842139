import React from 'react'
import lottie from 'lottie-web'
import animationData from './animation.json'

class MovingDots extends React.Component {
  // https://medium.com/@chenclaire/how-to-implement-lottie-animation-with-react-app-for-web-a6c14c4343e
  render() {
    return (
      <div
        className={this.props.className}
        ref={(ref) => (this.animBox = ref)}
      ></div>
    )
  }
  componentDidMount() {
    lottie.loadAnimation({
      container: this.animBox,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData,
    })
  }
}

export default MovingDots
